const es = {
  'Add New': 'Añadir nuevo',
  AddPlayer: 'Agregar jugador',
  'Added players': 'Jugadores agregados',
  'Africa/Central': 'Africa/Central',
  'African/East': 'Africa/Oriental',
  'All Messages': 'Todos los mensajes',
  'All Transactions': 'Todas las transacciones',
  'and see why thousands are moving their leagues to joga':
    'y mira por qué miles de personas están moviendo sus ligas a joga',
  AddPlayerToFavoritesError: 'Este jugador ya no participa en La Liga.',
  Advertising: 'Publicidad',
  Alignment: 'Alineación',
  'Australia/Center': 'Australia/Central',
  'Australia/East': 'Australia/Oriental',
  AlreadyJoinedError: 'Ya has sido invitado a esta liga.',
  Bad: 'Error',
  Birthdate: 'Fecha de nacimiento',
  Gender: 'Genero',
  'Brazil/East': 'Brasil/Oriental',
  Browse: 'Vistazo',
  'Bulk upload': 'Carga masiva',
  Calendar: 'Calendario',
  CardAboutJoga: {
    Title1: 'Crea o únete a una liga',
    Title2: 'Haz un draft',
    Title3: 'Dirige a tu equipo',
    Title4: 'Sigue tu partido',
    Image1: '../../assets/images/movil_1_es.png',
    Image2: '../../assets/images/movil_2_es.png',
    Image3: '../../assets/images/movil_3_es.png',
    Image4: '../../assets/images/movil_4_es.png',
  },
  CardArticleJoga: {
    Title1: '',
    Title2: '',
    Title3: '',
    Description1:
      'El fantasy es una experiencia que va a cambiar la manera que vives y ves el fútbol. Te contamos todos los detalles que debes saber',
    Description2:
      'Conseguir el título no será nada sencillo, por lo que el armado de tu equipo será una pieza clave para lograr el objetivo. Por ello, hemos creado esta guía con todo lo que necesitas saber acerca del sistema de draft de joga',
    Description3:
      'Luego del draft toca manejar tu equipo. Te damos recomendaciones de como elegir el mejor equipo de tu liga',
    TextButton: 'Leer más',
  },
  idealTeamTitle: 'Equipo Ideal de la semana ',
  idealTeamOfSeasonTitle: 'Equipo ideal de la temporada ',
  idealTeamMvp: 'Jugador Mas Valioso',
  PerformanceInformationTitle: 'Jugadores en racha',
  RecommendedPlayersTitle: 'Recomendados',
  'Canada/Newfoundland': 'Canadá/Terranova',
  'Central USA and Canada': 'Centro USA y Canada',
  'Check your email inbox "{{email}}" to complete the registration!':
    'Chequea tu bandeja de entrada de tu correo "{{email}}" para completar el registro!',
  'Check your email inbox': 'Chequea tu bandeja de entrada de tu correo',
  'Change Time Entry': 'Cambiar la entrada de tiempo',
  Classification: 'Clasificación',
  Closing: 'Clausura',
  ConfirmPassword: 'Confirmar contraseña',
  Contact: 'Contacto',
  Country: 'País',
  'Create a team': 'Crea un equipo',
  CreateLeague: 'Crear liga',
  'Create One Now': 'Crear una ahora',
  CreateTeam: 'Crear Equipo',
  CreateTeamError:
    'No puedes crear un equipo en esta liga. La liga ya ha comenzando.',
  CreateTeamGenericError: 'Ha ocurrido un error al crear el equipo.',
  CreateTeamTeamExistsError: 'Ya tienes un equipo en esta liga.',
  Created: 'Creado',
  Create: 'Crear',
  'Coordinated Universal Time': 'Tiempo Universal Coordinado',
  'Copied to the clipboard': 'Copiado en el portapapeles',
  Copy: 'Copiar',
  'Draft Date': 'Fecha del Draft',
  'Dropped players': 'Jugadores dejados',
  'Egypt/Arabic': 'Egipto/Arábico',
  EditLeague: 'Editar Liga',
  EditTeam: 'Editar Equipo',
  EditSuccessfully: 'Editado satisfactoriamente',
  Edit: 'Editar',
  'Enter date': 'Ingresa una fecha',
  EnterDraft: 'Entrar al draft',
  ExtraBox: 'Información adicional',
  Email: 'Correo electrónico',
  EmailOrUsername: 'Correo electrónico o nombre de usuario',
  'Email Address': 'Dirección de correo electrónico',
  English: 'Inglés',
  EnterAnEmailToInvite:
    'Usuarios de liga (Ingresa un correo electrónico para invitar a nuevos usuarios)',
  UserInvite: 'Usuario invitado',
  'Enter invitation code': 'Ingrese el código de invitación',
  'Enter your email': 'Ingresa tu correo electrónico',
  'Europe/Central': 'Europa/Central',
  'Europe/Eastern': 'Europa/Oriental',
  ExperienceTheThrillOfALive: 'Experimenta la emoción de un',
  FantasyDraft: 'Draft de fantasy fútbol',
  ExperienceTheThrillOfALiveDraftToSeeWhyThousandsAreMovingTheirLeaguesToJoga: `No te pierdas la oportunidad de vivir la`,
  ExperienceTheThrillOfALiveDraftToSeeWhyThousandsAreMovingTheirLeaguesToJogaEnd: `como nunca antes lo habías hecho`,
  FavoriteTeam: 'Equipo favorito',
  FantasyPointsPerGame: 'Puntos Fantasy por partido',
  FantasyPoints: 'Puntos fantasy',
  Firstnames: 'Nombres',
  ForgotYourPassword: '¿Olvidaste tu contraseña?',
  'Greenwich Mean Time': 'Meridiano de Greenwich',
  Hawaii: 'Hawai',
  'Here you can edit league settings':
    'Aquí puedes editar las configuraciones de la liga',
  'Indiana/East': 'Indiana/Oriental',
  'Invite players': 'Invitar jugadores',
  Japan: 'Japón',
  'joga fantasy Successful!': '¡joga Fantasy Éxito!',
  'joga fantasy Successful registration!': '¡joga Fantasy registro exitoso!',
  JoinALeague: 'Unirse a una liga',
  JoinPublicLeagueError:
    'No puedes unirte a esta liga. La liga ya ha comenzando.',
  JoinPrivateLeagueError:
    'No puedes unirte a esta liga. La liga ya ha comenzando.',
  JoinedToLeagueMessage: 'Te has unido a la liga',
  'Join to league': 'Unirse a la liga',
  Language: 'Idioma',
  Lastnames: 'Apellidos',
  'Last Post': 'Ultimos publicados',
  League: 'Liga',
  leagueIsNotActive: 'La liga no esta activa',
  leagueTeamIsNotActive:
    'No puedes agregar jugadores antes del inicio del draft',
  ResetPsw: 'Cambiar contraseña',
  emailLanguages: 'Idioma de correo',
  titleProfile: 'Perfil de Usuario',
  NoLeaguesMessage: 'No estas participando en ninguna liga',
  CreatedSuccessfully: 'Se ha creado exitosamente',
  InternalErrorMessage: 'Disculpe, ha ocurrido un error interno',
  LeagueStartOff: 'No puede agregar un jugador antes del inicio del draft.',
  exchangeProposal:
    'No puedes realizar la propuesta de intercambio con {0} porque su partido de la jornada {1} ya empezó.',
  addPlayers:
    'No puedes agregar a {0} a tu equipo porque su partido de la jornada {1} ya empezó.',
  dropPlayers:
    'No puedes dejar libre a {0} porque su partido de la jornada {1} ya empezó.',
  changePlayersErr:
    'No puedes cambiar a {0} porque su partido de la jornada {1} ya empezó.',
  changePlayers: 'Has cambiado a {0} por {1} exitosamente.',
  is_on_another_team: 'Ese jugador ya fue seleccionado en esta liga',
  Restart: 'Reiniciar',
  DW: 'DG',
  LaLiga: 'La Liga',
  'League Name': 'Nombre de la liga',
  Login: 'Iniciar Sesión',
  Logout: 'Cerrar Sesión',
  loginSuccessfully: 'Has iniciado sesión exitosamente',
  leagueNoLogIn: 'Inicia sesión para ver tus ligas',
  teamsNoLogIn: 'Inicia sesión para ver tus equipos',
  matchupNoLogIn: 'Inicia sesión para ver tus partidos',
  Match: 'Partido',
  Matchups: 'Partidos',
  PlayedMatches: 'Partidos jugados',
  'Match of the Week': 'Partido de la Semana',
  Messages: 'Mensajes',
  'Middle East': 'Medio Oriente',
  'Midway Islands': 'Islas midway',
  'My League': 'Mi liga',
  MyTeam: 'Mi equipo',
  'Near East': 'Próximo Oriente',
  'New Zealand': 'Nueva Zelanda',
  Pacific: 'Pacífico',
  Passes: 'Pases',
  Password: 'Contraseña',
  Page: 'Página',
  cannotContainOnlyNumbers: 'No puede contener sólo numeros',
  Phoenix: 'Fénix',
  'Pick a date': 'Elija una fecha',
  Player: 'Jugador',
  Players: 'Jugadores',
  PositionsTable: 'Tabla de posiciones',
  processing: 'Procesando',
  Profile: 'Perfil',
  Post: 'Enviados',
  PlayersShown: 'Jugadores mostrados',
  'Puerto Rico and US Virgin Islands':
    'Puerto Rico y las Islas Vírgenes de los Estados Unidos',
  Questions: 'Preguntas',
  Rank: 'Rango',
  'Ready to Start Your Own League?': '¿Listo para comenzar tu propia liga?',
  RecoverPassword: 'Recuperar contraseña',
  'Reset password': 'Restablecer la contraseña',
  Report: 'Reporte',
  'Resend activation mail': 'Reenviar correo de activación',
  'Rocky Mountains': 'Montañas Rocosas',
  Rules: 'Reglas',
  RulesUrl: '/notice-details/88',
  Saves: 'Atajadas',
  Score: 'Goles',
  Scorers: 'Anotadores',
  Scouting: 'Exploración',
  Shortcuts: 'Intercepciones',
  ShortcutsBlocksClear: 'Intercepciones, bloqueos, despejes y duelos',
  'Select a Shield': 'Seleccione un escudo',
  SelectPicture: 'Seleccionar imagen',
  'Select your country': 'Selecciona tu pais',
  'Select your favorite team': 'Selecciona tu equipo favorito',
  'Select your timezone': 'Seleccione su zona horaria',
  Send: 'Enviar',
  'Send recover password mail': 'Enviar correo de recuperación de contraseña',
  'Session was close, come back soon!':
    'La sesión fue cerrada, ¡vuelve pronto!',
  Setting: 'Ajustes',
  SignUp: 'Regístrate',
  'Sign Up Now': 'Regístrate Ahora',
  'Solomon Islands': 'Islas Salomón',
  Spanish: 'Español',
  Sponsors: 'Patrocinadores',
  Standings: 'Posiciones',
  Statistics: 'Estadísticas',
  'Success! A reset link was sent to your email. You have 24 hours to reset your password!':
    '¡Éxito! Un enlace fue enviado a tu correo. ¡Tienes 24 horas para restablecer tu contraseña!',
  'Success! Your new password was sent to your email!':
    '¡Éxito! Su nueva contraseña fue enviada a su correo electrónico!',
  Team: 'Equipo',
  Success: 'Éxito',
  Teams: 'Equipos',
  'Team Name': 'Nombre del equipo',
  'Team Positions': 'Posiciones',
  'The birthdate is required': 'La fecha de nacimiento es obligatoria',
  'The code is required': 'El código es obligatorio',
  'The country is required': 'El pais es obligatorio',
  'The date is required': 'La fecha es oblogatoria',
  'The date must be a valid date': 'La fecha debe ser válida',
  'The email confirmation was successful sended!':
    'El email de confirmación fue enviado exitosamente!',
  'The email field must be a valid email direction':
    'El campo de correo electrónico debe ser una dirección de correo electrónico válida',
  'The email field must be max 20 characters long':
    'El campo de correo electrónico debe tener un máximo de 20 caracteres',
  'The email is required': 'El correo electrónico es obligatorio',
  'The favorite team is required': 'El equipo favorito es obligatorio',
  'The firstnames are required': 'El nombre es obligatorio',
  'The firstnames field must be at least 4 characters long':
    'El campo de nombre debe tener al menos 4 caracteres',
  'The firstnames field must be max 20 characters long':
    'El campo de nombre debe tener un máximo de 20 caracteres',
  'The firstnames field must be numbers and letters characters':
    'El campo de nombre debe ser solo de caracteres de números y letras',
  TheGame: 'El Juego',
  'The lastnames are required': 'El apellido es obligatorio',
  'The lastnames field must be at least 4 characters long':
    'El campo de apellido debe tener al menos 4 caracteres',
  'The lastnames field must be max 20 characters long':
    'El campo de apellido debe tener un máximo de 20 caracteres',
  'The lastnames field must be numbers and letters characters':
    'El campo de apellido debe ser solo de caracteres de números y letras',
  'The league was successful edited!': '¡La liga fue editada exitosamente!',
  'The members must have a valid email address':
    'Los miembros deben tener una dirección de correo válida',
  'The name field must be at least 4 characters long':
    'El campo de nombre debe tener al menos 4 caracteres de largo',
  'The name field must be max 20 characters long':
    'El campo de nombre debe tener un máximo de 20 caracteres',
  'The name is required': 'El nombre es obligatorio',
  'The new password field must be at least 6 characters long':
    'El nuevo campo de contraseña debe tener al menos 6 caracteres',
  'The new password field must be max 20 characters long':
    'El nuevo campo de contraseña debe tener un máximo de 20 caracteres',
  'The new password is required': 'La nueva contraseña es obligatoria',
  'The password confirmation is required':
    'La confirmación de la contraseña es obligatoria',
  'The password confirmation must be equal to password field':
    'La confirmación de la contraseña debe ser igual al campo de contraseña',
  'The password field must be at least 6 characters long':
    'El campo de contraseña debe tener al menos 6 caracteres',
  'The password field must be max 20 characters long':
    'El campo de contraseña debe tener un máximo de 20 caracteres',
  'The password is required': 'La contraseña es obligatoria',
  'The team name is required': 'El nombre del equipo es obligatorio',
  'The time is required': 'La hora es oblogatoria',
  'The timezone is required': 'La zona horaria es obligatoria',
  'The username is required': 'El nombre de usuario es obligatorio',
  'The username field must be at least 4 characters long':
    'El campo de nombre de usuario debe tener al menos 4 caracteres',
  'The username field must be max 20 characters long':
    'El campo de nombre de usuario debe tener un máximo de 20 caracteres',
  'The username or email is required':
    'El nombre de usuario o correo electrónico es obligatorio',
  "This user isn't active, do you want to resend activation mail":
    'Este usuario no está activo, quieres reenviar el correo de activación',
  "You can't create a team in this league. Max quantity players already reached or the draft has already been done.":
    'No puedes crear un equipo en esta liga. El máximo de participantes ya se completó o el draft ha sido finalizado.',
  'Unable to add players who are not in the current tournament':
    'Este jugador ya no participa en La Liga.',
  Time: 'Hora',
  TimeZone: 'Zona horaria',
  Atencion: 'Atención',
  clickHere: 'click aquí',
  activationEmailTitle: 'Correo de activación',
  activationEmailButton: 'Enviar',
  notConfirm:
    'No has confirmado tu cuenta todavía. Por favor abre nuestro correo de activación y haz click en el enlace para seguir disfrutando joga fantasy.',
  notConfirmLineT:
    'Si no has recibido el correo de activación haz {0} para recibir un nuevo correo.',
  notConfirmLineTr:
    '{0} El correo pudo haber sido enviado a tu buzón de correos no deseados.',
  note: 'Nota:',
  notAccount: '¿No tienes cuenta? Registrate',
  activationEmail:
    'Un nuevo correo de activación ha sido enviado a ‘{0}’. Haz click en el enlace para activar tu cuenta y seguir disfrutando de joga fantasy.',
  Trades: 'Intercambios',
  Transactions: 'Transacciones',
  'Update league': 'Actualizar liga',
  'USA/Eastern': 'USA/Oriental',
  TooYoungToRegister: 'Debes ser mayor de 16 años para registrarte',
  Username: 'Nombre de usuario',
  'Username or email': 'Usuario o Correo',
  'View Staff': 'Ver personal',
  'Waiver Bids': 'Ofertas de renuncia',
  'Welcome back': 'Bienvenido de nuevo',
  WelcomeToJogaFantasy: 'Bienvenido a joga fantasy',
  WriteAMessage: 'Escribe un mensaje',
  YourAccountHasBeenConfirmed: 'Tu cuenta ha sido confirmada',
  YouCanEditLeagueSettingsLater:
    'Puede editar la configuración de la liga más tarde',
  YouCanEditTeamSettingsLater:
    'Puede editar la configuración del equipo más tarde',
  'Your league was successful registered. Create your team now!':
    'Tu liga fue creada exitosamente. ¡Crea tu equipo ahora!',
  'Your team was successful created!': '¡Tu equipo fue creado exitosamente!',
  Name: 'Nombre',
  Surname: 'Apellido',
  NewPassword: 'Nueva contraseña',
  RepeatYourNewPassword: 'Repita su nueva contraseña',
  ActivatedAccount: 'Cuenta Activada',
  PitchView: 'Vista de tono',
  MyTeamName: 'El nombre de mi equipo',
  Alternate: 'Suplentes',
  bench: 'Suplente',
  not_listed: 'No convocado',
  titular: 'Titular',
  starters: 'Titulares',
  playing: 'Jugando',
  substitute_out: 'Substituido',
  on_bench: 'En banca',
  stats: 'Estadísticas',
  watch: 'Ver',
  substitute: 'Sustituto',
  ReadyToStartYourOwnLeague: '¿Listo para comenzar tu propia liga?',
  CreateOneNow: 'Crear una ahora',
  DraftDate: 'Fecha del Draft',
  SelectTimeZone: 'selecciona la zona horaria',
  View: 'Ver',
  Defenders: 'Defensores',
  Defender: 'Defensor',
  Positions: 'Posiciones',
  Search: 'Buscar',
  PlayerName: 'Nombre del jugador',
  Pts: 'Puntos',
  Matches: 'Partidos',
  Finished: 'Finalizado',
  LastPost: 'Última publicación',
  NoTeamMessage: 'No tienes ningun equipo aún',
  NoMatchesMessage: 'No tienen ningun partido pendiente',
  ReturnTopics: 'Regresar a la lista de tópicos',
  Opponent: 'Oponente',
  Result: 'Resultado',
  News: 'Noticias',
  position: 'Posición',
  previous: 'Anterior',
  next: 'Siguiente',
  createTeamForLeague: 'Crear equipo para {0}',
  noResults: 'No hay resultados',
  draftStartsIn: 'El draft comienza en:',
  turnEndsIn: 'El turno termina en:',
  draftStoppedMessage: 'Draft detenido',
  itsYourTurnMessage: 'Es tu turno',
  gamesPlayed: 'Partidos jugados',
  gamesStarted: 'Juegos titular',
  goals: 'Goles',
  penaltyKickGoals: 'Goles de penal',
  save: 'Atajadas',
  shotOnTarget: 'Tiros a gol',
  interceptions: 'Intercepciones',
  tackleWon: 'Entradas ganadas',
  listEditor: 'Lista',
  paragraphEditor: 'Parrafo',
  titleEditor: 'Titulo',
  leftEditor: 'Izquierda',
  centerEditor: 'Centro',
  rightEditor: 'Derecha',
  aerialWon: 'Aéreos ganados',
  duelsWon: 'Duelos ganados',
  penaltyWon: 'Penaltis ganados',
  penaltyMissed: 'Penaltis fallados',
  penalty_won: 'Penalty Recibido',
  penalty_missed: 'Penalty Fallado',
  penalty_committed: 'Penalty Cometido',
  clearances_head: 'Despeje de Cabeza',
  saves_inside_box: 'Atajadas en el Area',
  saves_outside_box: 'Atajadas fuera del area',
  punches: 'Puñetazos',
  crosses_catched: 'Centros Atajados',
  high_claims: 'Centros Atajados',
  keeper_sweeps: 'Interepción Portero ',
  penalty_saved: 'Penalty Atajado',
  ball_recovery_gk: 'Recuperación de Portero',
  keyPass: 'Pases clave',
  hitWoodwork: 'Tiros al poste',
  clearances: 'Despejes',
  dispossession: 'Pérdidas de balón',
  penaltySaved: 'Penaltis parados',
  penaltyCommited: 'Penaltis causados',
  error_lead_to_goal: 'Errores',
  yellowCard: 'Tarjetas amarillas',
  redCard: 'Tarjetas rojas',
  accurateCross: 'Centros exitosos',
  goalAgainst: 'Goles Recibidos',
  goalReceived: 'Goles recibidos',
  goalAllowed: 'Goles permitidos',
  goal_winning: 'Gol Ganador',
  ownGoal: 'Gol en propia puerta',
  cleanSheet: 'Vallas invictas',
  secondAssist: 'Segundas asistencias',
  Assists: 'Asistencias',
  special_assist: 'Asistencia Especial',
  big_chance_created: 'Oportunidad de Gol Creada',
  big_chance_missed: 'Oportunidad de Gol Fallada',
  block: 'Bloqueo de Pase',
  blocked_cross: 'Bloqueo de Centro',
  ground_duel_won: 'Duelo Ganado',
  tackle_won: 'Entrada Ganada',
  ball_recovery: 'Recuperación de Balon',
  successful_dribbles: 'Drible Exitoso',
  own_goal: 'Gol en Propia Puerta',
  saves: 'Atajadas',
  selectedPlayer: 'Seleccionó a',
  noPlayersSelected: 'No se han seleccionado jugadores',
  Start: 'Empezar',
  Cancel: 'Cancelar',
  Goalkeepers: 'Porteros',
  Midfielders: 'Mediocampistas',
  Attackers: 'Atacantes',
  Goalkeeper: 'Portero',
  Midfielder: 'Mediocampista',
  Attacker: 'Atacante',
  PlayersSelection: 'Selección de jugadores',
  Selection: 'Selección',
  Position: 'Posición',
  AllPositions: 'Todas las posiciones',
  SortedBy: 'Ordenar por',
  SearchByName: 'Buscar por nombre',
  TotalScores: 'Total de anotaciones',
  TotalMinutesPlayed: 'Total de minutos jugados',
  Appearances: 'Apariciones',
  Notices: 'Noticias',
  Delete: 'Eliminar',
  CreateNotice: 'Crear Noticia',
  Title: 'Título',
  NoticeType: 'Tipo',
  Description: 'Descripción',
  draftFinishedMessage: 'Draft finalizado',
  draftFinishedDescription:
    'Ha finalizado el draft, ahora puedes ir al detalle de la liga para ver la programación de partidos, realizar intercambios y otras acciones',
  draftUnfinishedMessage: 'Draft interrumpido',
  draftUnfinishedNoTeamsDescription:
    'Se ha interrumpido el draft debido a que no habían equipos conectados o no había suficientes equipos en la liga.',
  draftUnfinishedConnectionDescription:
    'Se ha interrumpido el draft debido a problemas de conexión de los equipos',
  goToLeague: 'Ir a la liga',
  fieldRequiredMessage: 'Este campo es requerido',
  example: 'ej: {0}',
  invalidDate: 'Fecha inválida',
  invalidHour: 'Hora inválida',
  CreateThread: 'Crear hilo',
  PublishMessage: 'Publicar mensaje',
  Publish: 'Publicar mensaje',
  invalidEmail: 'Correo electrónico inválido',
  invalidCode: 'Código inválido',
  invalidInvitationForUser: 'La invitación no corresponde al usuario actual',
  ConnectionLost: 'Se ha perdido la conexión',
  ConnectionRecovered: 'Se ha recuperado la conexión',
  PlayerAlreadySelected: 'El jugador ya ha sido seleccionado',
  filterbyWeek: 'Filtrar por semana',
  ConfirmEmailToPlay:
    'Debes confirmar tu correo electrónico para acceder a joga',
  teamNameErrorMessage:
    'El nombre de tu equipo debe tener al menos 2 caracteres',
  tradeExecutionMessage: 'Este intercambio se procesará el ',
  waiverExecutionMessage: 'Esta solicitud de waiver se procesará el ',
  teamLogoErrorMessage: 'El logo del equipo es requerido',
  CleanSheets: 'Valla invicta',
  Defense: 'Defensa',
  SavesError: 'Error obteniendo tabla de salvadas.',
  ScoreError: 'Error obteniendo tabla de puntuaciones.',
  PassesError: 'Error obteniendo tabla de pases.',
  DefenseError: 'Error obteniendo tabla de defensa.',
  CleanSheetsError: 'Error obteniendo tabla de arcos en cero.',
  UserActiveAccount: '¡Este usuario no está activado!',
  UsernameError: 'El usuario debe ser mayor o igual a 3 caracteres',
  UsernameOrEmailError:
    'El usuario o correo electrónico debe ser mayor o igual a 3 caracteres',
  NameError: 'El nombre debe ser mayor o igual a 3 caracteres',
  SurnameError: 'El apellido debe ser mayor o igual a 3 caracteres',
  PasswordError: 'La contraseña debe ser entre 6 y 32 caracteres',
  EmailError: 'Debe ingresar un correo eléctronico válido',
  BirthdateError: 'Debe ingresar su fecha de nacimiento',
  FavoriteTeamError: 'Debe seleccionar un equipo',
  GenderError: 'Debe seleccionar un genero',
  CountryError: 'Debe seleccionar un país',
  TimeZoneError: 'Debe seleccionar una zona horaria',
  RegisterSuccess:
    'Se ha enviado un correo electrónico con las instrucciones para activar su cuenta!',
  RecoverySuccess:
    'Se ha enviado un correo electrónico con las instrucciones para recuperar su contraseña!',
  RecoveryConfirmSuccess: 'Su contraseña ha sido recuperada exitosamente!',
  ErrorMatchDetails: 'Error obteniendo los detalles del juego.',
  Resume: 'Resumen',
  MatchStatistics: 'Estadísticas del partido',
  Posetion: 'Posesión',
  ShotsToGol: 'Tiros a gol',
  MatchComments: 'Comentarios del partido',
  MatchInformation: 'información del partido',
  Stadium: 'Estadio',
  Assistance: 'Asistencia',
  Substitutes: 'Suplentes',
  ArticlesError: 'Error obteniendo artículos',
  PositionTableError: `Error obteniendo tabla de posiciones.`,
  DefenderAbr: 'DF',
  AttackerAbr: 'AT',
  GoalkeeperAbr: 'POR',
  MidfielderAbr: 'MC',
  gdabr: 'DG',
  MatchPlayedAbr: 'PJ',
  MatchWonAbr: 'PG',
  MatchLostAbr: 'PP',
  MatchTiedAbr: 'PE',
  None: 'Ninguno',
  CreateNoticeMessage: `Se ha creado la noticia exitosamente.`,
  CreateNoticeMessageError: `Ha ocurrido un error al crear la noticia.`,
  CreateNoticeMessageErrorUpload: `Ha ocurrido un error al cargar la imagen de la noticia.`,
  ImageError: 'Debes seleccionar una imagen.',
  SuccessEditArticle: `Se ha editado la noticia exitosamente.`,
  EditNoticeMessageError: `Ha ocurrido un error al editar la noticia.`,
  SuccessDeleteArticle: `Se ha eliminado la noticia exitosamente.`,
  DeleteNoticeMessageError: `Ha ocurrido un error al eliminar la noticia.`,
  Accept: 'Aceptar',
  YouMustDelete: '¿Desea eliminar',
  ErrorTitle: 'El título debe ser mayor o igual a 3 caracteres',
  ErrorDescription: 'La descripción debe ser mayor o igual a 20 caracteres',
  ErrorType: 'Debe seleccionar un tipo.',
  Height: 'Altura',
  Weight: 'Peso',
  Shooter: 'Patea',
  PlaceOfBorn: 'Lugar de nacimiento',
  Win: 'Ganado',
  Lost: 'Perdido',
  Pending: 'Pendiente',
  noSubstitutesForPosition: 'No tienes sustitutos para esta posición',
  SaveChanges: 'Guardar',
  Sending: 'Enviando',
  InvalidPositionForPlayer: 'No puedes colocar al jugador en esta posicion',
  LeagueStartsIn: ' - Inicia en {0} respecto a todas las zonas horarias',
  Turn: 'Turno',
  CurrentTurn: 'Turno Actual',
  Round: 'Ronda',
  selectionsForTurn: 'Elección para tu turno',
  selectionsForTurnPlural: 'Elecciones para tu turno',
  Posts: 'Publicaciones',
  Favorites: 'Favoritos',
  SearchFilter: 'Búsqueda',
  Welcome: 'Bienvenidos',
  Scores: 'Puntajes',
  SentBy: 'Enviado por',
  Copied: 'Copiado',
  SessionExpiredMessage:
    'Tu sesión ha expirado, por favor inicia sesión nuevamente',
  DraftInProgress: 'El draft está en progreso',
  minutes_played: 'Minutos Jugados',
  finished_games: 'Juegos Finalizados',
  SelectTeamShield: 'Seleccionar escudo',
  Load: 'Cargar',
  NotOnlyNumbersMessage: 'No puede contener sólo números',
  CannotContainOnlyNumbersAndSpecialChars:
    'No puede contener sólo números o caracteres especiales',
  CannotContainOnlySpecialChars: 'No puede contener sólo caracteres especiales',
  UsernameOrEmailRequired: 'El campo Usuario o Correo electrónico es requerido',
  OffSides: 'Fueras de juego',
  Corners: 'T. de esquina',
  Fouls: 'Faltas',
  FilterByTeam: 'Filtrar por equipo',
  FilterByRealTeam: 'Filtrar por equipos de ',
  FilterByFantasyTeam: 'Filtrar por equipos fantasy',
  FilterByStages: 'Filtrar por Temporada',
  FilterTeams: ' ',
  FT: 'F',
  NoStats: 'S/E',
  KP: 'PC',
  SOT: 'TG',
  HW: 'TP',
  AC: 'CE',
  CS: 'VI',
  GA: 'GP',
  AEA: 'AeA',
  AFA: 'AFA',
  GR: 'GR',
  CLR: 'DES',
  YC: 'TA',
  RC: 'TR',
  GP: 'PJ',
  GS: 'JT',
  SV: 'AT',
  SD: 'DE',
  TK: 'EG',
  DIS: 'PB',
  OG: 'GPP',
  AW: 'AG',
  PKW: 'PG',
  PKC: 'PC',
  PKS: 'PP',
  PKM: 'PF',
  G: 'G',
  GG: 'GG',
  PU: 'PU',
  HC: 'CA',
  IntP: 'IntP',
  A: 'A',
  PA: 'PA',
  R: 'R',
  RB: 'RB',
  AG: 'AG',
  DG: 'DG',
  E: 'E',
  PCO: 'PCo',
  DTA: '2TA',
  PTS: 'Pts',
  'P/G': 'P/P',
  AE: 'AE',
  A2: 'A2',
  OGC: 'OGC',
  OGF: 'OGF',
  CE: 'CE',
  DE: 'DE',
  Int: 'Int',
  BL: 'BL',
  BLC: 'BL-C',
  DESC: 'DES-C',
  TAP: 'TaP',
  GPY: 'G-Py',
  PR: 'PR',
  PF: 'PF',
  MP: 'MJ',
  FPG: 'PF/P',
  FPTS: 'FPts',
  penalty_kick_goal: 'Gol de Penalty',
  goalAgainstInMatches: '(AG)',
  goalInPenaltiesInMatches: '(P)',
  points_per_game: 'Puntos por juego',
  points_per_match: 'Puntos por partido',
  second_assists: 'Segunda Asistencia',
  accurate_cross: 'Centro Exitoso',
  AboutUs: 'Sobre nosotros',
  second_yellow_card: 'Doble Tarjeta Amarilla',
  PrivacyPolicy: 'Aviso de Privacidad',
  PrivacyPolicyUrl: '/notice-details/84',
  TermsAndConditions: 'Términos y condiciones',
  TermsAndConditionsUrl: '/notice-details/86',
  Week: 'Semana',
  Weeks: 'Semanas',
  You: 'Tú',
  Subject: 'Asunto',
  allplayers: 'Todos los jugadores',
  availablePlayer: 'Jugadores disponibles',
  DraftResults: 'Resultados del Draft',
  Results: 'Resultados',
  WonAbr: 'G',
  LostAbr: 'P',
  DrawAbr: 'E',
  YourContactRequestWasSent: 'Su solictud de contacto fue enviada',
  Choosing: 'Eligiendo',
  LeavePlayerQuestion: '¿Estás seguro que deseas dejar libre al jugador?',
  LeavingPlayerFree: 'Dejando libre al jugador',
  AddPlayerQuestion:
    '¿Estás seguro que deseas agregar este jugador a tu equipo?',
  AddingPlayer: 'Agregando jugador',
  Added: 'Agregado',
  Dropped: 'Liberado',
  FreeAgent: 'Desde Agentes libres',
  ToWaivers: 'Hacia Waivers',
  FieldPlayers: 'Jugadores de campo',
  Available: 'Disponible',
  MustBeOnlyLettersAndNumbers:
    'Debe estar compuesto de letras y numeros ó sólo letras',
  TradePlayers: 'Intercambiar jugadores',
  Continue: 'Continuar',
  YouMustSelectAPlayerToTrade:
    'Debes seleccionar al menos un jugador para intercambiar',
  YouMustSelectAPlayerToDelete:
    'Debes seleccionar al menos un jugador para remover',
  TradeRequestSent: 'Has enviado la solicitud de intercambio',
  emailDeUsuario: 'email de usuario',
  signUpSuccess:
    'Has creado tu cuenta exitosamente. Hemos enviado un correo a ‘{0}’ con instrucciones para activar tu cuenta. Haz click en continuar.',
  Trade: 'Intercambio',
  AllTeams: 'Todos los equipos',
  WaiverRequestSent: 'Pedido de waiver agregado',
  Building: 'En construcción',
  BuildingMessage: 'Nos encontramos trabajando en esta sección actualmente',
  WatchAll: 'Ver todas',
  WeekAbr: 'S',
  WaiverBids: 'Solicitudes de Waivers',
  Requested: 'Solicitado',
  RequestedFromWaivers: 'Solicitado desde waivers',
  Add: 'Agregar',
  SelectPlayersToOffer: 'Selecciona jugadores a ofrecer',
  SelectPlayerToDelete: 'Selecciona un jugador a remover',
  SelectPlayersToDelete: 'Selecciona jugadores a remover',
  FromWaivers: 'Desde Waivers',
  ReplacePlayer: 'Remplazar jugador',
  TradeRequests: 'Propuestas de intercambios',
  TradeRequestsFrom: 'Has recibido una propuesta de intercambio de',
  TradeRequestsTo: 'Has enviado una propuesta de intercambio a',
  TradeRequestsInProgresTo: 'Tu propuesta de intercambio ha sido aceptada por',
  TradeRequestExternalInProgress: '{0} y {1} han acordado un intercambio',
  TradeRequestRejectedFrom: 'Tu propuesta de intercambio ha sido rechazada por',
  TradeRequestRejectedTo: 'Haz rechazado una propuesta de intercambio de',
  TradeRequestInProgress: 'Haz aceptado una propuesta de intercambio de',
  TradeRequestCancelled: 'Tu solicitud de intercambio a {0} fue cancelada',
  TradeRequestCancelledAdmin:
    'Tu solicitud de intercambio a {0} fue cancelada por el administrador de la liga',
  TradeRequestReceivedCancelled: '{0} canceló la solicitud de intercambio',
  TradeRequestReceivedCancelledAdmin:
    'Le solicitud de intercambio de {0} fue cancelada por el administrador de la liga',
  SeeDetails: 'Ver detalles',
  Confirm: 'Confirmar',
  Rejected: 'Rechazado',
  Approved: 'Aprobado',
  MatchesAbr: 'P',
  UploadImage: 'Subir imagen',
  Cancelled: 'Cancelado',
  OP: 'Opo',
  op: 'Oportunidad',
  Opponet: 'Oponente',
  Today: 'Hoy',
  SuccessfullDribles: 'Dribles exitosos',
  minPlayersPositionError:
    'La cantidad mínima de jugadores para la posición de {0} es {1}',
  maxPlayersPositionError:
    'La cantidad máxima de jugadores para la posición de {0} es {1}',
  InProgress: 'En progreso',
  Completed: 'Completado',
  CancelAsLeagueAdmin: 'Cancelar como administrador',
  ConfirmCancelAsAdmin:
    '¿Estás seguro que deseas cancelar esta solicitud de intercambio como administrador de la liga?',
  CancelledByAdmin: 'Cancelado Por Admin.',
  Overview: 'Visión general',
  PersonalDetails: 'Detalles personales',
  Nationality: 'Nacionalidad',
  DateOfBirth: 'Fecha de nacimiento',
  BancomerPlayingCareer: 'Bancomer playing career',
  Season: 'Temporada',
  SeasonAbbr: 'TEMP',
  SelectPlayersToDemand: 'Seleccione los jugadores a demandar',
  Value: 'Valor',
  WaiverAbbr: 'W',
  WaiverRequestPending:
    'Haz enviado una solicitud para agregar a {0} desde Waivers.',
  WaiverRequests: 'Solicitudes de waivers',
  LeaveFreePlayersMessage:
    'Debes dejar libre a {0} jugador(es) para que este intercambio se complete',
  onlyNumbersError: 'Solo puede contener números',
  onlyNumbersOrLettersError: 'Solo puede contener números o letras',
  connectionErrorMessage: 'Por favor verifique su conexión a internet',
  imageTooBigErrorMessage:
    'El archivo es muy grande. Por favor intente con otra imagén.',
  badRequestMessage: 'Solicitud inválida',
  notFoundError:
    'La acción no pudo ser ejecutada, el recurso no fue encontrado',
  FirstHalf: 'Primera mitad',
  HalfTime: 'Medio tiempo',
  HalfTimeShort: 'MT',
  SecondHalf: 'Segunda mitad',
  'Full-time': 'Tiempo completo',
  Postponed: 'Pospuesto',
  PostponedShort: 'Posp.',
  Abandoned: 'Abandonado',
  'Delayed - delayed start': 'Inicio retrasado',
  playersToBreakFree: 'Debes seleccionar {0} jugador(es) para dejar libre(s)',
  breakingFreePlayer: 'Jugador a ser dejado libre',
  youMustSelectPlayersMessage: 'Debes seleccionar {0} jugador(es)',
  playersExcessMessage: `De concretarse este intercambio tendrias {0} jugadores en tu equipo, por lo que debes seleccionar {1} jugador(es) para ser dejado(s) libre(s) al momento de ejecutarse el intercambio.`,
  request_not_enough_players:
    'El equipo solicitante no puede quedar con menos de 11 jugadores',
  request_too_many_players:
    'El equipo solicitante no puede quedar con mas de 16 jugadores',
  request_doesnt_contain_player:
    'El equipo solicitante ya no posee uno o más jugadores de los implicados en el intercambio',
  receive_not_enough_players:
    'El equipo receptor no puede quedar con menos de 11 jugadores',
  receive_too_many_players:
    'El equipo receptor no puede quedar con mas de 16 jugadores',
  receive_doesnt_contain_player:
    'El equipo receptor ya no posee uno o más jugadores de los implicados en el intercambio',
  locked_players:
    'Alguno de los jugadores implicados en el intercambio se encontraba bloqueado para el momento de la ejecución del mismo',
  privateLeague: 'Liga privada',
  publicLeague: 'Liga pública',
  or: 'o',
  joinCreateLeagueModal: {
    tooltipTextPrivate:
      'Crea una liga con amigos y conocidos. Sólo los que invites serán parte de esta liga.',
    tooltipTextPublic:
      'Crea una liga abierta. Cualquier usuario parte de joga fantasy podrá unirse a tu liga.',
  },
  joinLeagueModal: {
    tooltipTextPrivate:
      'Puedes unirte a una liga privada si has recibido un código de invitación',
    tooltipTextPublic: 'Puedes unirte a cualquiera de nuestras ligas abiertas',
  },
  PublicLeagues: {
    title: 'Ligas públicas',
    bottomParagraph: {
      text: 'Si no deseas ser parte de ninguna de estas ligas puedes entrar en nuestra',
      waitingList: 'lista de espera',
    },
    tableHeader: {
      name: 'Nombre',
      commissioner: 'Comisionado',
      teams: 'Equipos',
      draft: 'Draft',
    },
    noData: {
      text1:
        'Actualmente no hay ligas públicas disponibles. Puedes unirte a nuestra ',
      waitingList: 'lista de espera',
      text2:
        ' y serás agregado a una nueva liga cuando haya 10 participantes disponibles. También puedes ',
      createLeague: 'crear una liga',
      text3: ' y esperar a que otros participantes se unan a ella.',
    },
  },
  waitingListModal: {
    title: 'Lista de espera',
    content:
      'Ha sido agregado a la lista de espera de ligas públicas. Cuando haya 10 integrantes serás agregado automáticamente a una nueva liga.',
    table: {
      header: {
        user: 'Usuario',
        username: 'Nombre de usuario',
        commissioner: 'Comisionado',
        country: 'País',
        favoriteTeam: 'Equipo favorito',
        joindDate: 'Se unió',
        guests: 'Invitado',
      },
    },
    error: 'Debe elegir al menos un invitado y un comisionado',
  },
  join: 'Unirse',
  maxParticipats: {
    label: 'Máximo número de participantes',
    placeholder: 'Seleccione el número de participantes',
  },
  leagueDuration: {
    label: 'Duración de la liga',
    placeholder: 'Seleccione la duración de la liga',
    placeholderAll: 'Toda la temporada',
  },
  tradeSuccess: 'Has agregado a {0} y dejado libre a {1} exitosamente',
  playerTradedAddedToWaiver:
    'Has agregado un pedido a la lista de waivers por {0}. De ser exitoso, {1} será dejado en libertad',
  playerAddedToWaiver: 'Has agregado un pedido a la lista de waivers por {0}',
  playerRemoved: 'Has dejado libre a {0} exitosamente',
  playerAdded: 'Has agregado a {0} exitosamente',
  playerAddedToFavorites: 'Has agregado a {0} a tu lista de favoritos',
  playerRemovedFromFavorites: 'Has removido a {0} de tu lista de favoritos',
  leagueCreated: 'Liga creada satisfactoriamente',
  Draft: {
    showAllSelectedPlayers: 'Mostrar sólo jugadores no elegidos',
  },
  draftMenu: 'Draft',
  legend: 'Leyenda',
  daysOfWeek: {
    DOM: 'Dom.',
    LUN: 'Lun.',
    MAR: 'Mar.',
    MIER: 'Mier.',
    JUE: 'Jue.',
    VIE: 'Vier.',
    SAB: 'Sáb.',
  },
  daysOfWeekFull: {
    DOM: 'domingo',
    LUN: 'lunes',
    MAR: 'martes',
    MIER: 'miercoles',
    JUE: 'jueves',
    VIE: 'viernes',
    SAB: 'sabado',
  },
  months: {
    Jan: 'Enero',
    Feb: 'Febrero',
    Mar: 'Marzo',
    Apr: 'Abril',
    May: 'Mayo',
    Jun: 'Junio',
    Jul: 'Julio',
    Aug: 'Agosto',
    Sep: 'Septiembre',
    Oct: 'Octubre',
    Nov: 'Noviembre',
    Dec: 'Diciembre',
  },
  position_abbreviation: {
    goalkeeper: 'Por.',
    defender: 'Def.',
    midfielder: 'Med.',
    attacker: 'Del.',
  },
  Career: 'Carrera',
  Injuries: 'Lesiones',
  Injury: 'Lesión',
  Date: 'Fecha',
  Duration: 'Duración',
  Transferences: 'Transferencias',
  Club: 'Club',
  LocalCup: 'Copas locales',
  InternationalCup: 'Copas internacionales',
  NationalTeam: 'Selección Nacional',
  Competition: 'Competencia',
  Journey: 'Jornada',
  OPO: 'Opo',
  Res: 'Res',
  goal_against: 'Gol a favor',
  Actual: 'Actual',
  From: 'Desde',
  To: 'A',
  Transfer: 'Transferencia',
  Free: 'Gratis',
  ofWord: ' de ',
  VideoPromoDesktop: 'https://youtu.be/d5D-1RufcVc',
  VideoPromoMobile: 'https://youtu.be/QoKNQlRbUJ0',
  VideoCreateLeague: 'https://youtube.com/shorts/Utm53ApVADI?feature=share',
  VideoInviteUsersLeague:
    'https://youtube.com/shorts/LoXk_i6TdeU?feature=share',
};

export default es;
